import React, { Component } from "react";

export default class DormIsolationRetestForm extends Component {

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <h2 className="col-xs-12">Antigen Retesting for Campus Housing Residents</h2>

        <p className="col-xs-12 text-azurite">
          <strong>Logged in as {this.props.netID}</strong>
        </p>

        <p className="col-xs-12 mt-3">
          <strong>In order to take a COVID-19 rapid antigen follow-up test, I attest that:</strong>
        </p>

        <div className="form-group col-xs-12">
          <div className="checkbox">
            <label htmlFor="requirement1">
              <input
                name="requirement1"
                id="requirement1"
                type="checkbox"
                checked={this.props.requirement1}
                onChange={this.props.handleCheck} />
              I have been fever-free for 24 hours without the use of fever-reducing medication.
            </label>
          </div>
        </div>

        <div className="form-group col-xs-12">
          <div className="checkbox">
            <label htmlFor="requirement2">
              <input
                name="requirement2"
                id="requirement2"
                type="checkbox"
                checked={this.props.requirement2}
                onChange={this.props.handleCheck} />
              My symptoms are improving or I am asymptomatic.
            </label>
          </div>
        </div>

        <div className="form-group col-xs-12">
          <div className="checkbox">
            <label htmlFor="requirement3">
              <input
                name="requirement3"
                id="requirement3"
                type="checkbox"
                checked={this.props.requirement3}
                onChange={this.props.handleCheck} />
              I understand that I must receive a negative antigen test result in order to return to my dorm prior to Day 10.
            </label>
          </div>
        </div>

        <div className="form-group col-xs-12">
          <div className="checkbox">
            <label htmlFor="requirement4">
              <input
                name="requirement4"
                id="requirement4"
                type="checkbox"
                checked={this.props.requirement4}
                onChange={this.props.handleCheck} />
              I understand that if my antigen retest result is positive, I must continue to isolate until Day 10.
            </label>
          </div>
        </div>
        <div className="col-xs-12 callout callout-info">
          Once you click <strong>NEXT</strong>, you will be directed to schedule an appointment for your follow up antigen test. Appointments are required.
          <br /><br />
          <strong>Make sure to choose the option for Campus-Wide - Rapid Antigen and schedule your test for Day 6 or after.</strong> Day 0 is the date your sample was collected for your positive test.
          <br /><br />
          If you have any questions, please contact the COVID Ambassador Team (CAT) Hotline at 520-848-4045 or <a href="mailto:covidhelp@arizona.edu">covidhelp@arizona.edu</a>.
        </div>
        <div className="col-xs-12">
          <input
            disabled={!this.props.submitEnabled}
            type="submit"
            value="NEXT"
            className="btn btn-lg btn-blue mt-4">
          </input>
        </div>
      </form>
    );
  }
}