import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import { wifiSnoozeAuthz } from "../../authorization";
import axios from 'axios';

import CustomOAuthButton from '../../CustomOAuthButton';

export default class WifiSnoozeRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attestation: null,
      snoozeExpires: null,
      snoozes: [],
      submitted: false,
      submitting: false,
      errmsg: null,
      error: null,
      loading: true,
      is_elg: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);

  }

  async componentDidUpdate(prevProps) {
    if (this.props.authz !== prevProps.authz) {
      this.setState({
        loading: true
      }, () => {
        wifiSnoozeAuthz().then(res => {
          
          let is_elg = res.is_elg;
          let errmsg = null;
          let snoozes = [];
          
          if (res.hasOwnProperty('errmsg')) {
            errmsg = res.errmsg;
          }
          if (res.hasOwnProperty('snoozes')) {
            snoozes = res.snoozes;
          }
          this.setState({
            is_elg: is_elg,
            errmsg: errmsg,
            snoozes: snoozes,
            loading: false
          });
        }).catch(error => {
          this.setState({loading: false})
        });    
      })
    }
  }
  
  async componentDidMount() {
    // check if we were redirected from Cognito
    if (this.props.location.search.startsWith("?code=")) {
      this.setState({
        loading: true
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }


  handleSubmit() {

    this.setState({ error: null, submitting: true })

    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      const data = {};      
      let submission = {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: data,
      }

      return API.post("wifiSnoozePublish", "/wifiSnoozePublish", submission)
        .then(res => {
            this.setState({errmsg: null, snoozeExpires: res.snooze_expires, submitting: false, submitted: true});
        })
        .catch((error) => {
          console.log(error);
          this.setState({errmsg: error.response.errmsg, loading: false, snoozeExpires: null});
        });
    }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
    });
  }

  renderSnoozeDialog() {
    
    const { 
      submitting,
    } = this.state;

    const submitEnabled = (
      !submitting 
    );
  
    return (
      <>
      <h2 className="col-xs-12">UAWiFi Snooze Button</h2>
      <h3 className="col-xs-12">Click the <strong>SNOOZE</strong> button below to use a UAWiFi snooze.</h3>
      <div className="col-xs-12">
        <div className="callout callout-info">
          <p>You have used <strong>{this.state.snoozes.length}</strong> of your 3 UAWiFi snoozes for the semester.</p>
        </div>
        {
          this.state.snoozes.length > 0 &&
          <div className="callout callout-info">
            <p>Your previous snoozes were on:</p>
            <ul>
            {
              this.state.snoozes.map(
                (snz, i) => (
                  <li key={`snooze-${i}`}><strong>{snz}</strong></li>
                )
              )
            }
            </ul>
          </div>
        }
      </div>
      <div className="col-xs-12">
        <center>
          <button type="button" className="btn btn-primary btn-lg" onClick={this.handleSubmit} disabled={!submitEnabled}>Snooze</button>
        </center>
      </div>
      </>
    );
  }

  renderLoading() {
    return (
      <div className="mx-auto row" style={{width:75}}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8"/>
      </div>
    );
  }

  renderLogin() {
    return (
    <>
    <h2 className="col-xs-12">UAWiFi Snooze Button</h2>
    <div className="col-xs-12">
      <div className="callout callout-red">
        <p>Please log in with your NetID to proceed.</p>
        <div className="mt-4">
          <CustomOAuthButton variant="primary" size="lg">LOGIN</CustomOAuthButton>
        </div>
      </div>
    </div>
    </>
    )
  }

  renderUnauthorized() {
    return (
    <>
    <h2 className="col-xs-12">UAWiFi Snooze Button</h2>
    <div className="col-xs-12">
      <div className="callout callout-red">
        <h4>Not Authorized</h4>
        <p>You are not authorized to use the UAWiFi Snooze button because {this.state.errmsg}.</p>
      </div>
    </div>
    </>
    )
  }

  renderSubmitted() {
    return (
      <>
        <h2 className="col-xs-12">UAWiFi Snooze Button</h2>
        <div className="col-xs-12">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" width="3em" height="3em"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"></path></svg>
          <p className="my-4">Your UAWiFi snooze request has been submitted.</p>
          <div className="callout callout-warning">
            <strong>Important:</strong> It typically takes less than 15 minutes for your WiFi snooze to go into effect, but may take up to 1 hour.
          </div>
          <div className="callout callout-warning">
            Your WiFi snooze will expire at <strong>{this.state.snoozeExpires}</strong>.
          </div>
          <div className="callout callout-azurite callout-dark">
            To reconnect to UAWiFi you must, disconnect from all WiFi networks on your device. Once you are disconnected, select UAWiFi in order to reconnect.
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div>
        { this.props.loading || this.state.loading ? this.renderLoading() : 
          <>
            { this.props.authz && this.state.is_elg !== null ? 
              <>
                { (!this.state.is_elg || this.state.errmsg) ? 
                  this.renderUnauthorized()
                : 
                  <>
                    {!this.state.submitted && this.renderSnoozeDialog()}
                    {this.state.submitted && this.renderSubmitted()}
                  </>
                }
              </>
            : 
              this.renderLogin()
            }
          </>
        }
      </div>
    );
  }
}