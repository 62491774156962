import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import { researchOptInAuthz } from "../../authorization";

import CustomOAuthButton from '../../CustomOAuthButton';
import ResearchOptInForm from './ResearchOptInForm';

export default class ResearchOptIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optIn: false,
      submitted: false,
      error: null,
      loading: true,
      optInAuthz: null,
    };

    this.handleCheck = this.handleCheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  async componentDidUpdate(prevProps) {
    if (this.props.authz !== prevProps.authz) {
      this.setState({
        loading: true
      }, () => {
        researchOptInAuthz().then(res => {
          this.setState({
            optInAuthz: res,
            loading: false
          });
        }).catch(error => {
          this.setState({loading: false})
        });    
      })
    }
  }
  
  async componentDidMount() {
    // check if we were redirected from Cognito
    if (this.props.location.search.startsWith("?code=")) {
      this.setState({
        loading: true
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  handleCheck(event) {
    const target = event.target;
    const value = target.checked;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({error: null, submitting:true }, () => {
      Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        const data = {
          authz_only: false,
        };
        
        let submission = {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          },
          body: data,
        }
  
        return API.post("researchOptInPublish", "/researchOptInPublish", submission)
          .then(result => {
              this.setState({error: null, submitting: false, submitted: true});
          })
          .catch((error) => {
            this.setState({
              optInAuthz: error.response.data.error
            })
            console.log(error);
          });
      }).catch(error => {
          console.log("Error in Auth.currentSession: " + error);
          return [];
      });
    })
  }

  renderForm() {
    const { 
      submitting,
      optIn,
    } = this.state;

    const submitEnabled = (
      !submitting &&
      optIn
    );
  
    return ( 
        <ResearchOptInForm 
          optIn={this.state.optIn}
          handleCheck={this.handleCheck}
          handleSubmit={this.handleSubmit}
          submitEnabled={submitEnabled}>
        </ResearchOptInForm>
    );
  }

  renderLoading() {
    return (
      <div className="mx-auto row" style={{width:75}}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8"/>
      </div>
    );
  }

  renderLogin() {
    return (
    <>
    <h2 className="col-xs-12"> COVID-19 Testing and Vaccination Research Opt-In</h2>
    <div className="col-xs-12">
      <div className="callout callout-red">
        <p>Please log in with your NetID to proceed.</p>
        <div className="mt-4">
          <CustomOAuthButton variant="primary" size="lg">LOGIN</CustomOAuthButton>
        </div>
      </div>
    </div>
    </>
    )
  }

  renderUnauthorized() {
    return (
      <>
      <h2 className="col-xs-12"> COVID-19 Testing and Vaccination Research Opt-In</h2>
      <div className="col-xs-12">
        <div className="callout callout-red">
          {
            this.state.optInAuthz === 'no_test_or_vax' &&
            <>
              <h3 class="mt-0">Not Eligible</h3>
              <p>
                This form is for campus community members who have previously taken a COVID-19 test on campus through Test All Test Smart and/or who have shared their vaccination information with the University.
                <br/><br/>
                Because you have <strong>not</strong> tested with Test All Test Smart and have not shared your vaccination information with the University, you are not eligible to complete this form. If you register to test in the future or share your vaccination information, you will then have the opportunity to opt in to having your de-identified data be available for research purposes.
              </p>
            </>
          }
          {
            this.state.optInAuthz === 'under18' &&
            <>
              <h3 class="mt-0">Not Eligible</h3>
              <p>
                This form is for campus members who are 18 years of age or older. Because you are under 18 years of age, you are not eligible. 
              </p>
            </>
          }
          {
            this.state.optInAuthz === 'prior_optin' &&
            <>
              <h3 class="mt-0">Already Opted In</h3>
              <p>
                You previously opted in to having your de-identified testing data and de-identified vaccination data be available for future research purposes. If you change your mind at any time, you can opt out (withdraw your consent) by emailing our Test, Trace &amp; Treat team at <a href="mailto:t3@arizona.edu.">t3@arizona.edu</a>.
              </p>
            </>
          }
        </div>
      </div>
      </>
    )
  }

  renderSubmitted() {
    return (
      <>
        <h2 className="col-xs-12"> COVID-19 Testing and Vaccination Research Opt-In</h2>
        <div className="col-xs-12 d-flex align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" width="3em" height="3em"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"></path></svg>
          <span class="text-size-h3 pl-2"><strong>Opt-In Confirmed</strong></span>
        </div>
        <div className="col-xs-12 mt-2">
          <div class="callout callout-info">
            <p>
              Your de-identified testing data and de-identified vaccination data may be used for future research purposes.
              <br/><br/>
              If you have questions, please contact us at <a href="mailto:t3@arizona.edu.">t3@arizona.edu</a>.
            </p>
          </div>
        </div>
      </>
    );
  }

  render() {
    console.log(JSON.stringify(this.props));
    return (
      <div>
        
        { this.props.loading || this.state.loading ? this.renderLoading() : 
          <>
            { this.props.authz && this.state.optInAuthz !== null ? 
              <>
                { (this.state.optInAuthz !== 'ok') ? 
                  this.renderUnauthorized()
                : 
                  <>
                    {!this.state.submitted && this.renderForm()}
                    {this.state.submitted && this.renderSubmitted()}
                  </>
                }
              </>
            : 
              this.renderLogin()
            }
          </>
        }
      </div>
    );
  }
}