import React, { Component } from "react";
import Spinner from 'react-spinkit';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';


export default class TestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser().then(user => {
      this.setState({netID: user.attributes['custom:netid']});
      this.props.refreshAuthz()
        .then(res => {
          this.setState({loading: false});
        });
    });
  }

  renderTestList() {

    console.log(this.props.authz)

    var authorized_pcr =  this.props.authz.authorized_pcr;
    var registered_pcr =  this.props.authz.registered_pcr;
    var results_pcr    =  this.props.authz.results_pcr;
    var consent_pcr =  this.props.authz.consent_pcr;

    var authorized_ag =  this.props.authz.authorized_ag;
    var registered_ag =  this.props.authz.registered_ag;
    var results_ag    =  this.props.authz.results_ag;
    var consent_ag =  this.props.authz.consent_ag;

    var is_elg_pcr = this.props.authz.is_elg_pcr;
    var elg_date_pcr = is_elg_pcr ? '' : this.props.authz.elg_date_pcr;
    var is_elg_ag = this.props.authz.is_elg_ag;
    var elg_date_ag = is_elg_ag ? '' : this.props.authz.elg_date_ag;
    
    const allowedTests = [];

    if (authorized_ag) {
      if (!is_elg_ag) {
        allowedTests.push({name: 'Rapid Antigen Testing', shortName: 'Antigen', descr: 'Nasal swab – self-administered', display: 'an antigen', registered: 'ineligible', results: results_ag, elg_date: elg_date_ag } );
      } else if (consent_ag) {
        allowedTests.push({name: 'Rapid Antigen Testing', shortName: 'Antigen', descr: 'Nasal swab – self-administered', registered: true, results: results_ag});
      } else {
        allowedTests.push({name: 'Rapid Antigen Testing', shortName: 'Antigen', descr: 'Nasal swab – self-administered', registered: false, route: '/antigen-registration'});
      }
    }

    if (authorized_pcr) {
      if (!is_elg_pcr) {
        allowedTests.push({name: 'PCR Testing', shortName: 'PCR', descr: 'Saline gargle – self-administered', display: 'a PCR', registered: 'ineligible', results: results_pcr, elg_date: elg_date_pcr } );
      } else if (consent_pcr) {
        allowedTests.push({name: 'PCR Testing', shortName: 'PCR', descr: 'Saline gargle – self-administered', registered: true, results: results_pcr});
      } else {
        allowedTests.push({name: 'PCR Testing', shortName: 'PCR', descr: 'Saline gargle – self-administered', registered: false, route: '/pcr-registration'});
      }
    }

    return (
      <>
        <h2 className="col-xs-12">
          COVID-19 Test Registration
        </h2>
        <p className="col-xs-12 text-azurite">
          <strong>Logged in as { this.state.netID }</strong>
        </p>
        
        { allowedTests.length ?   
          <>
            <p className="mb-5 col-xs-12">
              You are eligible to register for the following tests. Learn more about the different types of tests at <a href="https://covid19.arizona.edu/covid19-testing" target="_blank" rel="noopener noreferrer">COVID-19 Testing.</a>.<br/>Information about the test sites and schedules is available at <a href="https://covid19.arizona.edu/covid19-testing/locations" target="_blank" rel="noopener noreferrer">Testing Locations &amp; Hours</a>.
            </p>
            <div className="row col-xs-12">
              { allowedTests.map(
                (test) => (
                  <div className="col-md-4 col-sm-6 col-xs-12" key={test.name}>
                    <div className="card card-block" style={{ borderColor: '#ab0520' }}>
                        <h3 className="text-size-h3 card-title sans mb-2">{test.name}</h3>
                        <p className="mb-3">
                          <em>{test.descr}</em>
                        </p>
                        { test.registered === 'ineligible' &&
                          <p>
                            You are not eligible for {test.display} test today. You will be eligible for another test on <b>{test.elg_date}</b>.
                          </p>
                        }
                        { test.registered === true &&
                          <div className="pt-1 text-red">
                            <p><strong>Already registered</strong></p>
                          </div>
                        }
                        { test.registered === false &&
                          <Link to={test.route}>
                              <button type="button" className="btn btn-primary">
                                  {`Register for ${test.shortName}`}
                              </button>
                          </Link>
                        }
                    </div>
                    { test.shortName === "PCR" && test.registered === true &&
                        <div className="card-footer mb-4">
                          <a href="https://testing.wellcheck.arizona.edu/appointments" target="_blank" rel="noopener noreferrer">Schedule a PCR Test on Main Campus</a>
                        </div>
                    }
                    {
                      test.registered === false &&
                        <div className="card-footer text-muted mb-4">
                          Available on Main Campus
                        </div>
                    }
                    { 
                      test.shortName === "Antigen" && test.registered === true &&
                        <div className="card-footer mb-4">
                          <a href="https://testing.wellcheck.arizona.edu/appointments" target="_blank" rel="noopener noreferrer">Schedule an Antigen Test on Main Campus</a>
                        </div>
                    }
                    { 
                      test.registered === 'ineligible' &&
                        <div className="card-footer mb-4">
                          If you have questions, please check the test results portal at <a href="https://results.wellcheck.arizona.edu" target="_blank" rel="noopener noreferrer">https://results.wellcheck.arizona.edu</a> for your recent test result.
                        </div>
                    }
                  </div>
                ))
              }
            </div>
 
          </>
        : 
          <div className="col-xs-12">
            <div className="callout callout-red">
              <h4>Not Authorized</h4>
              <p>You are not authorized to register for COVID-19 testing at this time.</p>
            </div>
          </div>
        }
        {
          (registered_ag || registered_pcr) &&
          <p className="col-xs-12 text-azurite">
            <strong>Show this QR code when checking-in for your test:</strong><br/>
            <img src={"https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl="+this.state.netID+"@email.arizona.edu"}/>
          </p>
        }  
      </>
    );
  }

  renderLoading() {
    return (
      <div className="mx-auto row" style={{width:75}}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8"/>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.loading && this.renderLoading()}
        {!this.state.loading && this.renderTestList()}
      </div>
    );
  }
}
