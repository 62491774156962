import React, { Component } from "react";

export default class ResearchOptInForm extends Component {

  render() {
    return (
      <>
        <form onSubmit={this.props.handleSubmit}>
          <h2 className="col-xs-12">COVID-19 Testing and Vaccination Research Opt-In</h2>
          
          <p className="col-xs-12">
            The University of Arizona would like to offer you the opportunity to <strong>opt in</strong> to have your de-identified testing data and de-identified vaccination data available for future COVID-19 research.
          </p>

          <p className="col-xs-12">
            <a href="https://privacy.arizona.edu/privacy-resources" target="_blank" rel="noopener noreferrer">De-identified data</a> is defined as data that has <strong>all personally identifiable information removed</strong> to prevent someone&apos;s personal identity from being linked back to the data.
          </p>

          <p className="col-xs-12">
            All research proposals that include a request to access, use, or share COVID-19 institutional data are subject to review and approval by the University of Arizona Institutional Review Board (IRB) and/or the COVID-19 Data Governance Committee.
          </p>

          <p className="col-xs-12">
            If you would like to <strong>opt in</strong> at this time, or at any time in the future, please do so below. If you choose to not complete this form, your de-identified data will be excluded from any future research project (you will be opted out).
          </p>

          <p className="col-xs-12">
            If you have questions, please contact our Test All Test Smart program at <a href="mailto:t3@arizona.edu">t3@arizona.edu</a>.
          </p>

          <div className="form-group col-xs-12 mt-2">
            <div className="checkbox">
              <label htmlFor="optIn">
                <input
                  name="optIn"
                  id="optIn"
                  type="checkbox"
                  checked={this.props.optIn}
                  onChange={this.props.handleCheck}/>I <strong>opt in</strong> to having my de-identified testing data and de-identified vaccination data be available for future research purposes.
              </label>
            </div>
          </div>
          
          <p className="col-xs-12">
            If you change your mind at any time, you can <strong>opt out (withdraw your consent)</strong> by emailing our Test, Trace &amp; Treat team at <a href="mailto:t3@arizona.edu">t3@arizona.edu</a>. Please note that your de-identified data may have already been released.
          </p>

          <div className="col-xs-12 my-2">
            <input 
                    disabled={!this.props.submitEnabled}
                    type="submit"
                    value="Submit"
                    className="btn btn-lg btn-blue mt-4">
            </input>
          </div>
        </form>
      </>
    );
  }
}