import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom';
import Spinner from 'react-spinkit';

import AntigenForm from './AntigenForm';
import CustomOAuthButton from '../CustomOAuthButton';
 
export default class AntigenRegistration extends Component {

  renderForm() {
    return (
      <AntigenForm authz={this.props.authz}
                   netID={this.props.netID}
                   name={this.props.name}>
      </AntigenForm>
    )
  }

  renderRedirect() {
    return (
      <>
        <h2 className="col-xs-12">Antigen Test Registration</h2>
        <Redirect 
          to={{
            pathname: "/locations-hours",
            state: { netID: this.props.netID, name: this.props.name }
          }}
        />
      </>
    );
  }

  renderUnauthorized() {
    return (
    <>
    <h2 className="col-xs-12">Antigen Test Registration</h2>
    <div className="col-xs-12">
      <div className="callout callout-red">
        <h4>Not Authorized</h4>
        <p>You are not authorized to register for a COVID-19 antigen test at this time.</p>
        <Link to='/'>
            Return to Registration Home
        </Link>
      </div>
    </div>
    </>
    )
  }

  renderLogin() {
    return (
    <>
    <h2 className="col-xs-12">Antigen Registration</h2>
    <div className="col-xs-12">
      <div className="callout callout-red">
        <p>Please log in with your NetID to register for COVID-19 testing.</p>
        <div className="mt-4">
          <CustomOAuthButton variant="primary" size="lg">LOGIN</CustomOAuthButton>
        </div>
      </div>
    </div>
    </>
    )
  }

  renderLoading() {
    return (
      <div className="mx-auto row" style={{width:75}}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8"/>
      </div>
    );
  }

  render() {
    return (
      <div>
        { this.props.loading ? this.renderLoading() : 
          <>
            { this.props.authz ? 
              <>
                { (!this.props.authz.authorized_ag || this.props.authz.consent_ag) ? 
                  this.renderUnauthorized()
                : 
                  this.renderForm()
                }
              </>
            : 
              this.renderLogin()
            }
          </>
        }
      </div>
    );
  }
}
