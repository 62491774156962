import React, { Component } from "react";
import { Navbar, Button } from "react-bootstrap";
import "./App.css";
import CustomOAuthButton from './CustomOAuthButton';
import Amplify, {Auth, Hub} from 'aws-amplify';
import config from "./config";
import {authz} from "./authorization";
import Routes from "./Routes";
import t3logo from './images/t3logo.png';
import ScrollToTop from './components/ScrollToTop'

const oauth = {
  domain: config.cognito.DOMAIN,
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: config.cognito.REDIRECT_SIGNIN.replace(/\/+$/, "") + document.location.pathname.replace(/\/+$/, "") + "/",
  redirectSignOut: config.cognito.REDIRECT_SIGNOUT,
  responseType: 'code' // or token
};

Amplify.configure({
  Auth: {
    oauth: oauth,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "testRegPublish",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "testRegAuthz",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      // disabled 2021-04-11 : CTRDS-253
      // {
      //   name: "testsmartSurveyPublish",
      //   endpoint: 'https://hw6nf8z470.execute-api.us-gov-west-1.amazonaws.com/prd',
      //   region: config.apiGateway.REGION
      // },
      {
        name: "testExemptionAuthz",
        endpoint: 'https://hw6nf8z470.execute-api.us-gov-west-1.amazonaws.com/prd',
        region: config.apiGateway.REGION
      },
      {
        name: "testExemptionPublish",
        endpoint: 'https://hw6nf8z470.execute-api.us-gov-west-1.amazonaws.com/prd',
        region: config.apiGateway.REGION
      },
      {
        name: "testExemptionUploadSig",
        endpoint: 'https://hw6nf8z470.execute-api.us-gov-west-1.amazonaws.com/prd',
        region: config.apiGateway.REGION
      },
      {
        name: "dccTestReqAuthz",
        endpoint: 'https://hw6nf8z470.execute-api.us-gov-west-1.amazonaws.com/prd',
        region: config.apiGateway.REGION
      },
      {
        name: "dccTestReqPublish",
        endpoint: 'https://hw6nf8z470.execute-api.us-gov-west-1.amazonaws.com/prd',
        region: config.apiGateway.REGION
      },
      {
        name: "wifiSnoozeAuthz",
        endpoint: 'https://hw6nf8z470.execute-api.us-gov-west-1.amazonaws.com/prd',
        region: config.apiGateway.REGION
      },
      {
        name: "wifiSnoozePublish",
        endpoint: 'https://hw6nf8z470.execute-api.us-gov-west-1.amazonaws.com/prd',
        region: config.apiGateway.REGION
      },
      {
        name: "dormIsolationRetestPublish",
        endpoint: 'https://a8hbnudn9e.execute-api.us-gov-west-1.amazonaws.com/prd',
        region: config.apiGateway.REGION
      },
      {
        name: "researchOptInPublish",
        endpoint: 'https://a8hbnudn9e.execute-api.us-gov-west-1.amazonaws.com/prd',
        region: config.apiGatewayStack3.REGION
      }
    ]
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    this.signOut = this.signOut.bind(this);
    this.refreshAuthz = this.refreshAuthz.bind(this)
    
    this.state = {
      authn: 'loading',
      authz: false
    };

    // let the Hub module listen on Auth events
    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
    });
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then(user => {
      this.setState({
        netID: user.attributes['custom:netid'],
        name: user.attributes.name.split(' ').join('%20'),
        authn : 'signedIn',
        loading: true
      }, this.refreshAuthz);
    }).catch(e => {
      this.setState({authn: 'signIn', authz: false});
    });
  }

  onAuthEvent(payload) {
    // The Auth module will emit events when user signs in, signs out, etc
    switch (payload.event) {
      case 'signIn':
        Auth.currentAuthenticatedUser().then(user => {
          this.setState({
            netID: user.attributes['custom:netid'],
            name: user.attributes.name.split(' ').join('%20'),
            authn : 'signedIn',
            loading: true
          }, this.refreshAuthz);
        });
        break;
      case 'signIn_failure':
        this.setState({authn: 'signIn', authz: false});
        break;
      default:
        break;
    }
  }

  signOut() {
    this.setState({authn: 'signIn', authz: false});
    Auth.signOut({ global: true });
  }

  async refreshAuthz() {
    return authz().then(res => {
      this.setState({authz: res, loading: false,});
    }).catch(error => {
      this.setState({loading: false})
    });
  }

  render() {
    const { authn, loading } = this.state;
    return (
      authn !== 'loading' &&
      <div className="App container mb-8">
        <Navbar>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-xs-6 col-sm-4 my-2">
                <a href="https://www.arizona.edu/test-trace-treat" title="Test All Test Smart | Home" target="_blank" rel="noopener noreferrer" className="remove-external-link-icon"><img src={t3logo} style={{maxWidth: '200px'}} alt="Test Trace Treat | Home"/></a>
              </div>
              <div className="col-md-1 col-md-offset-7 col-sm-1 col-sm-offset-6 col-xs-offset-2 col-xs-1">
                {authn === 'signIn' && !document.location.pathname.startsWith('/testing-requirement') && <CustomOAuthButton>Login</CustomOAuthButton>}
                {authn === 'signedIn' && !document.location.pathname.startsWith('/testing-requirement') && <Button bsstyle="primary" onClick={this.signOut}>Logout</Button>}
              </div>
            </div>
          </div>
        </Navbar>
        <Routes
          childProps={{
            authn: this.state.authn,
            authz: this.state.authz,
            refreshAuthz: this.refreshAuthz,
            signOut: this.signOut,
            loading: loading,
            netID: this.state.netID,
            name: this.state.name
          }}
        />
        <ScrollToTop/>
      </div>
    );
  }
}

export default App;
