import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-spinkit';

import Start from './Start';
import Address from './Address';
import Demographics from './Demographics';
import GuardianConsent from './GuardianConsent';
import ConsentAuth from './PCRConsentAuth';
import { zipcodeOptions, dormOptions } from './Choices';

export default class PCRForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resType: '',
      isMinor: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
      zipcodeValid: null,
      zipcodeError: null,
      dorm: '',
      phoneNumber: '',
      ethnicity: '', 
      race: '',
      sex: '',
      consentToTest: false,
      authorizeRelease: false,
      submitted: false,
      error: null,
      loading: false,
      smsOptInRemindersNews: null,
      smsOptInRegResults: null,
      researchOptIn: false,
    };

    this.handleCheck = this.handleCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResType = this.handleResType.bind(this);
    this.handleZipcode = this.handleZipcode.bind(this);
    this.setPhoneNumber = this.setPhoneNumber.bind(this);
    this.setDorm = this.setDorm.bind(this);
    this.setDormFromAuthz = this.setDormFromAuthz.bind(this);
  }

  async componentDidMount() {
    
    var affiliation = this.props.authz.affiliation;

    if (affiliation === 'student-on') {
      this.setState({resType: 'D'}, this.setDormFromAuthz(this.props.authz.dorm_name));
    } else if (affiliation === 'student-off') {
      this.setState({resType: 'ND'});
    } else if (affiliation === 'employee') {
      this.setState({resType: 'FS'});
    }
  }

  setDormFromAuthz(dormName) {
    var dorm = dormOptions.filter(obj => {
      return obj.display === dormName;
    })[0];

    var values = dorm.value.split(", ");
    this.setState({
      dorm: values[0],
      address: values[1],
      city: values[2],
      state: values[3],
      zipcode: values[4],
      zipcodeValid: true,
    });
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleCheck(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleResType(event) {
    if (this.state.resType === 'D' && event.target.value !== 'D') {
      this.setState({
        dorm: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        zipcodeValid: null,
        zipcodeError: null,
      });
    }
    this.setState({resType: event.target.value});
  }

  handleZipcode(event) {
    
    var zipcodeValid = false;
    var zipcodeError = null;
    
    var zipcode = event.target.value;
    
    if (zipcode.length === 5) {
      if (zipcodeOptions.includes(zipcode)) {
        zipcodeValid = true;
      } else {
        zipcodeError = 'Invalid zip code. Please enter a valid Arizona zip code.'
      }
    } else if (zipcode.length === 0) {
      zipcodeError = null;
    } else {
      zipcodeError = 'Zip code must be 5 digits.'
    }

    this.setState({
      zipcode: zipcode,
      zipcodeValid: zipcodeValid,
      zipcodeError: zipcodeError,
    });
  }

  setDorm(event) {
    if (event.target.value === '') {
      this.setState({
        dorm: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        zipcodeValid: null,
      });
    } else {
      var values = event.target.value.split(", ");
      this.setState({
        dorm: values[0],
        address: values[1],
        city: values[2],
        state: values[3],
        zipcode: values[4],
        zipcodeValid: true,
      });
    }
  }

  setPhoneNumber(value, countryData) {
    this.setState({phoneNumber: value});
  }

  async handleSubmit(event) {

    this.setState({error: null, loading:true })

    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      const data = {
        test_type: 'pcr',
        sex: this.state.sex,
        race: this.state.race,
        ethnicity: this.state.ethnicity,
        street_address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zipcode,
        phone: this.state.phoneNumber,
        res_type: this.state.resType,
        dorm_name: this.state.dorm,
        sms_opt_in_reg_results: (this.state.smsOptInRegResults === 'Y' || this.props.authz.smsTesting_pcr) ? true : false,
        sms_opt_in_reminders_news: (this.state.smsOptInRemindersNews === 'Y' || this.props.authz.smsReminders_pcr) ? true : false
      };
      
      let submission = {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: data,
      }

      return API.post("testRegPublish", "/testRegPublish", submission)
        .then(async result => {
            if (this.state.researchOptIn) {
              // opt-in to using data for research purposes
              submission.body = {
                authz_only: false,
                reg_only: true,
              };
              await API.post("researchOptInPublish", "/researchOptInPublish", submission)
                .then(result => {})
                .catch((error) => {});
            }
            this.setState({error: null, loading: false })
            this.setState({ 'submitted': true });
        })
        .catch((error) => {
          var message = error.response.data.error;
          var display = '';
          if (message === 'missing required parameters') {
            display = 'One or more required fields are missing.';
          } else if (message === 'user already registered') {
            display = 'You have already registered for your test.';
          } else if (message === "unauthorized") {
            display = 'You are not authorized to register for a test at this time.';
          } else {
            display = 'There was an error submitting your registration. Please try again.'
          }
          this.setState({error: display, loading: false });
        });
    }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
    });

    event.preventDefault();
  }

  renderForm() {
    
    const { 
      resType,
      dorm,
      phoneNumber,
      ethnicity,
      race,
      sex,
      address,
      city,
      state,
      zipcodeValid,
      authorizeRelease,
      consentToTest,
      loading,
      smsOptInRegResults,
      smsOptInRemindersNews
    } = this.state;

    const submitEnabled = (
      !loading &&
      phoneNumber.length > 10 &&
      (address.length && city.length && state.length && zipcodeValid) && 
      ethnicity.length &&
      race.length &&
      sex.length &&
      (authorizeRelease || this.props.authz.parentalConsent_pcr) &&
      (consentToTest || this.props.authz.parentalConsent_pcr) &&
      ((resType === 'D' && dorm.length) || resType !== 'D') &&
      (smsOptInRegResults !== null || this.props.authz.smsTesting_pcr === true || this.props.authz.smsTesting_pcr === false) &&
      (smsOptInRemindersNews !== null || this.props.authz.smsReminders_pcr === true || this.props.authz.smsReminders_pcr === false)
    );
  
    return ( 
      <form onSubmit={this.handleSubmit}>
        
        <h2 className="col-xs-12">
          RT-PCR Test Registration
        </h2>
        
        <p className="col-xs-12 text-azurite">
          <strong>Logged in as { this.props.netID }</strong>
        </p>
        
        { this.props.authz.under18 && !this.props.authz.parentalConsent_pcr ? 
          <GuardianConsent
            netID={this.props.netID}
            name={this.props.name}
            consentCode={this.props.authz.consentCode_pcr}
            link='https://redcap.uahs.arizona.edu/surveys/?s=CJM3DX3ATJ'
            testType='pcr'>
          </GuardianConsent>
        : <>
            <p className="my-4 col-xs-12">
              Thank you for registering for a COVID-19 RT-PCR test. The following
              questions and response options are required by the Arizona Department
              of Health Services and the CDC.
            </p>
            { !this.state.resType && 
              <Start handleResType={this.handleResType}
                     resType={this.state.resType}
                     setDorm={this.setDorm}> 
              </Start>
            }
            { this.state.resType && 
              <>
                {this.state.resType !== 'D' && 
                  <Address
                    handleChange={this.handleChange}
                    resType={this.state.resType}
                    setDorm={this.setDorm}
                    handleZipcode={this.handleZipcode}
                    zipcodeError={this.state.zipcodeError}
                    zipcodeValid={this.state.zipcodeValid}> 
                  </Address>
                }

                <Demographics
                  handleChange={this.handleChange}
                  setPhoneNumber={this.setPhoneNumber}> 
                </Demographics>

                { 
                  !this.props.authz.under18 &&
                  <ConsentAuth
                    handleCheck={this.handleCheck}
                    handleChange={this.handleChange}
                    authorizeRelease={this.state.authorizeRelease}
                    consentToTest={this.state.consentToTest}
                    researchOptIn={this.state.researchOptIn}
                    smsOptInRegResults={this.state.smsOptInRegResults}
                    smsOptInRemindersNews={this.state.smsOptInRemindersNews}>
                  </ConsentAuth>
                }
                
                <div className="col-xs-12 my-4">
                  <p>
                    You will receive a QR code via UA email and text for checking in to your test.
                  </p>
                  <p>
                    <strong className="text-red"> 
                      When you click Next, you will be routed to the Testing Locations and Hours page so that you can determine the most convenient time and location for your test.
                    </strong>
                  </p>
                  <input disabled={!submitEnabled}
                         type="submit"
                         value="Next"
                         className="btn btn-lg btn-blue mt-4"/>
                  
                  { this.state.error && 
                    <p className="text-red mt-4">
                      <strong>Error: {this.state.error}</strong>
                    </p> 
                  }
                </div>
              </>
            }
          </>
        }
      </form>

    );
  }

  renderRedirect() {
    return (
      <>
        <h2 className="col-xs-12">RT-PCR Test Registration</h2>
        <Redirect 
          to={{
            pathname: "/onestop"
          }}
        />
      </>
    );
  }

  renderLoading() {
    return (
      <div className="mx-auto row" style={{width:75}}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8"/>
      </div>
    );
  }

  render() {
    return (
      <div>
        { this.props.loading ? this.renderLoading() : 
          <>
            {!this.state.submitted && this.renderForm()}
            {this.state.submitted && this.renderRedirect()}
          </>
        }
      </div>
    );
  }
}