import { Auth, API } from 'aws-amplify';

export const authz = () => {
  return Auth.currentSession().then(session => {
    const token = session.idToken.jwtToken;
    let myInit = { // OPTIONAL
      headers: {
        Authorization: token
      }
    }
    return API.get("testRegAuthz", "/testRegAuthz", myInit).then(response => {
//      console.log(JSON.stringify(response));
      return response;
    }).catch(error => {
      console.log(error);
      console.log("Error in authz API call: " + error.response);
      return false;
    });
  }).catch(error => {
    console.log("Error in Auth.currentSession: " + error.response);
    return false;
  });
}

export const accommodationAuthz = () => {
  return Auth.currentSession().then(session => {
    const token = session.idToken.jwtToken;
    let myInit = { 
      headers: {
        Authorization: token
      }
    }
    return API.get("testExemptionAuthz", "/testExemptionAuthz", myInit).then(response => {
      return response;
    }).catch(error => {
      console.log("Error in authz API call: " + error.response);
      return false;
    });
  }).catch(error => {
    console.log("Error in Auth.currentSession: " + error.response);
    return null;
  });
}

export const dccAuthz = () => {
  return Auth.currentSession().then(session => {
    const token = session.idToken.jwtToken;
    let myInit = { 
      headers: {
        Authorization: token
      }
    }
    return API.get("dccTestReqAuthz", "/dccTestReqAuthz", myInit).then(response => {
      return response;
    }).catch(error => {
      console.log("Error in authz API call: " + error.response);
      return false;
    });
  }).catch(error => {
    console.log("Error in Auth.currentSession: " + error.response);
    return null;
  });
}

export const researchOptInAuthz = () => {
  return Auth.currentSession().then(session => {
    const token = session.idToken.jwtToken;
    const data = {
      authz_only: true,
    };
    let myInit = { 
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      body: data
    }
    return API.post("researchOptInPublish", "/researchOptInPublish", myInit).then(response => {
      return "ok";
    }).catch(error => {
      return error.response.data.error;
    });
  }).catch(error => {
    console.log("Error in Auth.currentSession: " + error.response);
    return null;
  });
}

export const wifiSnoozeAuthz = () => {
  return Auth.currentSession().then(session => {
    const token = session.idToken.jwtToken;
    let myInit = { 
      headers: {
        Authorization: token
      },

    }
    return API.get("wifiSnoozeAuthz", "/wifiSnoozeAuthz", myInit).then(response => {
      return response;
    }).catch(error => {
      console.log("Error in authz API call: " + error.response);
      return false;
    });
  }).catch(error => {
    console.log("Error in Auth.currentSession: " + error.response);
    return null;
  });
}

