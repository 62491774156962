import React, { Component } from "react";
import {stateOptions} from './Choices'

export default class Address extends Component {

  render() {

    return (
      <>
        <label className="form-group col-xs-12">What is your Arizona address? If you are visiting from another state or country, please enter the local address where you will be staying while in Arizona.</label>
        <div className="form-group col-xs-12">
          <label htmlFor="address">Street Address<span className="text-red">*</span></label>
          <input
            className="form-control"
            type="text"
            name="address"
            id="address"
            maxLength="50"
            value={this.props.address}
            onChange={this.props.handleChange}/>
        </div>
        <div className="form-group col-md-4 col-sm-12 col-xs-12">
          <label htmlFor="city">
            City
            <span className="text-red">*</span>
          </label>
          <input 
            className="form-control"
            type="text"
            name="city"
            id="city"
            maxLength="50"
            value={this.props.city}
            onChange={this.props.handleChange}/>
        </div>
        <div className="form-group col-md-4 col-sm-12 col-xs-12">
          <label htmlFor="state">
            State
            <span className="text-red">*</span>
          </label>
          <select
            value={this.props.state}
            onChange={this.props.handleChange.bind(this)}
            name='state'
            id="state"
            className='form-control'>
            {stateOptions.map((option) => <option key={option} value={option}>{option}</option>)}
          </select>
        </div>
        <div className="form-group col-md-4 col-sm-12 col-xs-12">
          <label htmlFor="zipcode">
            Zip Code
            <span className="text-red">*</span>
          </label>
          <input
            className="form-control"
            type="text"
            name="zipcode"
            id="zipcode"
            value={this.props.zipcode}
            maxLength="5"
            onChange={this.props.handleZipcode}/>
            { this.props.zipcodeValid ?
              <small className="mb-1 text-sage"><span className="ua-brand-checkmark"></span> Valid zip code</small> :
              <>
                { this.props.zipcodeError ? 
                  <small className="mb-1 text-red">{ this.props.zipcodeError }</small> :
                  <small className="mb-1">Please enter a 5-digit Arizona zip code.</small>
                }
              </>
            }
        </div>
      </> 

    );
  }
}