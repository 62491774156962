import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import DormIsolationRetestForm from './DormIsolationRetestForm';
import CustomOAuthButton from '../CustomOAuthButton';


export default class DormIsolationRetest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      retestOk: null,
      submitted: false,
      submitting: false,
      loading: true,
      requirement1: false,
      requirement2: false,
      requirement3: false,
      requirement4: false
    };
    this.checkRetestOk = this.checkRetestOk.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    // check if we were redirected from Cognito
    if (this.props.location.search.startsWith("?code=")) {
      this.setState({
        loading: true
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.authz !== prevProps.authz) {
      this.setState({ isLoading: true, retestOk: false },
        () => {
          this.checkRetestOk();
        }
      );
    }
  }

  handleCheck(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async checkRetestOk() {
    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      const data = {
        authz_only: true,
      };

      console.log(data);
      
      let submission = {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: data,
      }

      return API.post("dormIsolationRetestPublish", "/dormIsolationRetestPublish", submission)
        .then(result => {
            this.setState({
              retestOk: true,
              loading: false
            });
        })
        .catch((error) => {
          this.setState({
            retestOk: false,
            loading: false
          });
        });
    }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
    });
  }

  handleSubmit(event) {

    this.setState({error: null, submitting:true })

    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      const data = {
        authz_only: false,
      };
      
      let submission = {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: data,
      }

      return API.post("dormIsolationRetestPublish", "/dormIsolationRetestPublish", submission)
        .then(result => {
            this.setState({ submitting: false, submitted: true });
        })
        .catch((error) => {
          console.log(error);
          // var display = 'There was an error submitting your request. Please make sure you answered all questions and try again.'
          this.setState({loading: false, submitting: false, submitted: false });
        });
    }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
    });

    event.preventDefault();
  }

  renderForm() {
    const { 
      submitting,
      requirement1,
      requirement2,
      requirement3,
      requirement4
    } = this.state;

    const submitEnabled = (
      !submitting &&
      requirement1 &&
      requirement2 &&
      requirement3 &&
      requirement4
    );

    return (
      <DormIsolationRetestForm
        handleCheck={this.handleCheck}
        handleSubmit={this.handleSubmit}
        submitEnabled={submitEnabled}
        netID={this.props.netID}
        name={this.props.name}>
      </DormIsolationRetestForm>
    )
  }

  renderRedirect() {
    return (
      <>
        <h2 className="col-xs-12">Antigen Retesting for Campus Housing Residents</h2>
        <Redirect 
          to={{
            pathname: "/appointments"
          }}
        />
      </>
    );
  }

  renderUnauthorized() {
    return (
    <>
    <h2 className="col-xs-12">Antigen Retesting for Campus Housing Residents</h2>
    <div className="col-xs-12">
      <div className="callout callout-red">
        <h4>Not Authorized</h4>
        <p>You are not authorized to register for follow-up COVID-19 antigen testing at this time.</p>
      </div>
    </div>
    </>
    )
  }

  renderLogin() {
    return (
    <>
    <h2 className="col-xs-12">Antigen Retesting for Campus Housing Residents</h2>
    <div className="col-xs-12">
      <div className="callout callout-red">
        <p>Please log in with your NetID to register for follow-up COVID-19 antigen testing.</p>
        <div className="mt-4">
          <CustomOAuthButton variant="primary" size="lg">LOGIN</CustomOAuthButton>
        </div>
      </div>
    </div>
    </>
    )
  }

  renderLoading() {
    return (
      <div className="mx-auto row" style={{width:75}}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8"/>
      </div>
    );
  }

  render() {
    return (
      <div>
        { this.props.loading || this.state.loading ? this.renderLoading() : 
          <>
            { this.props.authz && this.state.retestOk !== null ? 
              <>
                { (!this.state.retestOk) ? 
                  this.renderUnauthorized()
                : 
                  <>
                    {!this.state.submitted && this.renderForm()}
                    {this.state.submitted && this.renderRedirect()}
                  </>
                }
              </>
            : 
              this.renderLogin()
            }
          </>
        }
      </div>
    );
  }
}