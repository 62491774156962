import React, { Component } from "react";
import {ethnicityOptions, raceOptions, sexOptions} from './Choices'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default class Demographics extends Component {

  render() {
    return (
      <>
      <div className="form-group col-xs-12">
        <label tabIndex="0" htmlFor="cellphone">What is your cell phone number? <span className="text-red">*</span></label>
        <br></br>
        <PhoneInput
          country={'us'}
          value={this.props.phoneNumber}
          onChange={this.props.setPhoneNumber}
          countryCodeEditable={false}
          inputClass="form-control"
          placeholder="please enter your cellphone number"
          inputProps={{ id: 'cellphone', name: 'cellphone' }}
        />
        <small className="mb-1">Please provide a US number if you have one.</small>
      </div>
      <div className="form-group col-xs-12">
        <label htmlFor="ethnicity">What is your ethnicity? <span className="text-red">*</span></label>
        <select value={this.props.ethnicity} onChange={this.props.handleChange.bind(this)} name='ethnicity' id="ethnicity" className="form-control">
          {ethnicityOptions.map((option) => <option key={option.value} value={option.value}>{option.display}</option>)}
        </select>
      </div>
      <div className="form-group col-xs-12">
        <label htmlFor="race">What is your race? <span className="text-red">*</span></label>
        <select value={this.props.race} onChange={this.props.handleChange.bind(this)} name='race' id="race" className="form-control">
          {raceOptions.map((option) => <option key={option.value} value={option.value}>{option.display}</option>)}
        </select>
      </div>
      <div className="form-group col-xs-12">
        <label htmlFor="sex">What is your sex? <span className="text-red">*</span></label>
        <select value={this.props.sex} onChange={this.props.handleChange.bind(this)} name='sex' id="sex" className="form-control">
          {sexOptions.map((option) => <option key={option.value} value={option.value}>{option.display}</option>)}
        </select>
      </div>
      </>
    );
  }
}