import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import AntigenRegistration from "./containers/AntigenRegistration";
// import AntibodyRegistration from "./containers/AntibodyRegistration";
import PCRRegistration from "./containers/PCRRegistration";
//disabled 2021-04-11 : CTRDS-253
// import TestSmart from "./containers/TestSmart";
// import AccommodationRequest from "./containers/AccommodationRequest/AccommodationRequest";
import TestingRequirements from "./containers/AccommodationRequest/TestingRequirements";
// import RemoteLearning from "./containers/AccommodationRequest/RemoteLearning";
// import DCCRequest from "./containers/DCCRequest/DCCRequest";
import WifiSnoozeRequest from "./containers/WifiSnooze/WifiSnoozeRequest";
import DormIsolationRetest from "./containers/DormIsolationRetest";
import ResearchOptIn from "./containers/ResearchOptIn/ResearchOptIn";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/antigen-registration" exact component={AntigenRegistration} props={childProps} />
    {/* <AppliedRoute path="/antibody-registration" exact component={AntibodyRegistration} props={childProps} /> */}
    <AppliedRoute path="/pcr-registration" exact component={PCRRegistration} props={childProps} />
    {/* <AppliedRoute path="/test-smart" exact component={TestSmart} props={childProps} /> */}
    {/* <AppliedRoute path="/accommodation-request" exact component={AccommodationRequest} props={childProps} /> */}
    {/* <AppliedRoute path="/remote-learning" exact component={RemoteLearning} props={childProps} /> */}
    <AppliedRoute path="/testing-requirement" exact component={TestingRequirements} props={childProps} />
    {/* <AppliedRoute path="/dcc" exact component={DCCRequest} props={childProps} /> */}
    <AppliedRoute path="/wifi-snooze" exact component={WifiSnoozeRequest} props={childProps} />
    <AppliedRoute path="/dormretest" exact component={DormIsolationRetest} props={childProps} />
    <AppliedRoute path="/opt-in-research" exact component={ResearchOptIn} props={childProps} />
    <Route
      path='/schedule-antigen'
      component={
        (props) => {
          window.location.href = 'https://redcap-gw.wellcheck.arizona.edu/?pid=8857'; 
          return null;
        }
      }
    />
    <Route
      path='/schedule-pcr'
      component={
        (props) => {
          window.location.href = 'https://redcap-gw.wellcheck.arizona.edu?pid=9178'; 
          return null;
        }
      }
    />
    {/* <Route
      path='/schedule-testsmart'
      component={
        (props) => {
          window.location.href = 'https://redcap-gw.wellcheck.arizona.edu?pid=9522'; 
          return null;
        }
      }
    /> */}
    <Route
      path='/schedule-ab'
      component={
        (props) => {
          window.location.href = 'https://redcap-gw.wellcheck.arizona.edu?pid=9522'; 
          return null;
        }
      }
    />
    <Route
      path='/locations-hours'
      component={
        (props) => {
          window.location.href = 'https://covid19.arizona.edu/covid19-testing/locations'; 
          return null;
        }
      }
    />
    <Route
      path='/appointments'
      component={
        (props) => {
          window.location.href = 'https://outlook.office365.com/owa/calendar/UACOVID19Testing@emailarizona.onmicrosoft.com/bookings/';
          return null;
        }
      }
    />
    <Route
      path='/grad-appointments'
      component={
        (props) => {
          window.location.href = 'https://outlook.office365.com/owa/calendar/CommencementCeremonyTesting@emailarizona.onmicrosoft.com/bookings/';
          return null;
        }
      }
    />
    <Route
      path='/antibody-registration'
      component={
        (props) => {
          window.location.href = 'https://covid19antibodytesting.arizona.edu/';
          return null;
        }
      }
    />
    <Route
      path='/onestop'
      component={
        (props) => {
          window.location.href = 'https://onestop.wellcheck.arizona.edu'; 
          return null;
        }
      }
    />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
