const prod = {
  apiGateway: {
    REGION: "us-gov-west-1",
    URL: "https://7zj1u3rfy9.execute-api.us-gov-west-1.amazonaws.com/prd"
  },
  apiGatewayStack3: {
    REGION: "us-gov-west-1",
    URL: "https://a8hbnudn9e.execute-api.us-gov-west-1.amazonaws.com/prd"
  },
  cognito: {
    REGION: "us-gov-west-1",
    USER_POOL_ID: "us-gov-west-1_Yerqo6rUv",
    APP_CLIENT_ID: "4e450ll69pq651l3o3dkjsda5g",
    DOMAIN: "ctrds.auth-fips.us-gov-west-1.amazoncognito.com",
    REDIRECT_SIGNIN: "https://testing.wellcheck.arizona.edu/",
    REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
  },
  misc: {
    PCR_YOUTUBE_ID: "jnzUOsDo4yE"
  }
};

const test = {
  apiGateway: {
    REGION: "us-gov-west-1",
    URL: "https://7zj1u3rfy9.execute-api.us-gov-west-1.amazonaws.com/prd"
  },
  apiGatewayStack3: {
    REGION: "us-gov-west-1",
    URL: "https://a8hbnudn9e.execute-api.us-gov-west-1.amazonaws.com/prd"
  },
  cognito: {
    REGION: "us-gov-west-1",
    USER_POOL_ID: "us-gov-west-1_Yerqo6rUv",
    APP_CLIENT_ID: "4e450ll69pq651l3o3dkjsda5g",
    DOMAIN: "ctrds.auth-fips.us-gov-west-1.amazoncognito.com",
    REDIRECT_SIGNIN: "https://reg-test.wellcheck.arizona.edu/",
    REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
  },
  misc: {
    PCR_YOUTUBE_ID: "jnzUOsDo4yE"
  }
};

const dev = {
  apiGateway: {
    REGION: "us-gov-west-1",
    URL: "https://7zj1u3rfy9.execute-api.us-gov-west-1.amazonaws.com/prd"
    // URL: "https://9bm89pl9ak.execute-api.us-west-2.amazonaws.com/dev"
  },
  apiGatewayStack3: {
    REGION: "us-gov-west-1",
    URL: "https://a8hbnudn9e.execute-api.us-gov-west-1.amazonaws.com/prd"
  },
  cognito: {
    REGION: "us-gov-west-1",
    USER_POOL_ID: "us-gov-west-1_Yerqo6rUv",
    APP_CLIENT_ID: "4e450ll69pq651l3o3dkjsda5g",
    DOMAIN: "ctrds.auth-fips.us-gov-west-1.amazoncognito.com",
    REDIRECT_SIGNIN: "http://localhost:4200/",
    REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
  },
  misc: {
    PCR_YOUTUBE_ID: "jnzUOsDo4yE"
  }
};

const configMap = {
  'production': prod,
  'testing': test,
  'development': dev
};

const config = configMap[process.env.REACT_APP_STAGE];

export default {
  apiGateway: config.apiGateway,
  apiGatewayStack3: config.apiGatewayStack3,
  cognito: config.cognito,
  misc: config.misc
};