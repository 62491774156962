import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom';
import Spinner from 'react-spinkit';
import YouTube from 'react-youtube';
import PCRForm from './PCRForm';
import CustomOAuthButton from '../CustomOAuthButton';
import config from "../config";

import '../css/YouTube.css'

export default class PCRRegistration extends Component {

  constructor(props) {
    super(props);
    this.videoEndCallback = this.videoEndCallback.bind(this);
    this.continueCallback = this.continueCallback.bind(this);
    this.state = {
      showContinue: false,
      userContinue: false
    };
  }

  videoEndCallback() {
    this.setState({ showContinue: true });
  }

  continueCallback() {
    this.setState({ userContinue: true });
  }

  renderVideo() {
    const opts = {
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        end: 63
      },
    };
    return (
    <>
    <div className="row">
      <h2 className="col-xs-12">RT-PCR Test Registration</h2>
      <p className="col-xs-12">Please watch the video or read the instructions below to learn about the PCR saline gargle test. You will be able to click the CONTINUE button and proceed with registration after the video plays through (1:04).</p>
      <div className="col-xs-12">
        <YouTube
          videoId={config.misc.PCR_YOUTUBE_ID}
          opts={opts}
          onEnd={this.videoEndCallback}
          containerClassName={"youtubeContainer"}
        />
        <button disabled={!this.state.showContinue} className="btn btn-lg btn-blue mt-4" onClick={this.continueCallback}>Continue</button>
      </div>
    </div>
    <div className="row mt-3">
      <h2 className="col-xs-12">How To Do A Saline Gargle Test</h2>
      <p className="col-xs-12">Know before you go: Do not eat, drink, use mouthwash, brush your teeth, chew gum, smoke or vape for 30 minutes before your saline gargle test.</p>
    </div>
    <div className="callout callout-info row">
      <strong>Step 1: Squeeze the saline</strong>
      <br/><br/>
      Twist the top off the saline packet like you’re turning a key, then lower your mask. Squeeze all of the saline into your mouth. Replace your mask and discard the empty saline packet.
    </div>
    <div className="callout callout-info row">
      <strong>Step 2: Swish and gargle</strong>
      <br/><br/>
      Swish the saline around your mouth for 5 seconds. Tilt your head back and gargle for 10 seconds.
      <br/><br/>
      <strong>Repeat cycle 3 times.</strong>
    </div>
    <div className="callout callout-info row">
      <strong>Step 3: Spit</strong>
      <br/><br/>
      Lower your mask and gently spit the saline into the funnel. Replace your mask.
    </div>
    <div className="callout callout-info row">
      <strong>Step 4: Loosen the funnel</strong>
      <br/><br/>
      Slowly unscrew the funnel allowing the saline to flow into the tube.
    </div>
    <div className="callout callout-info row">
      <strong>Step 5: Discard the funnel and screw on the cap.</strong>
    </div>
    </>
    )
  }

  renderForm() {
    return (
      <PCRForm authz={this.props.authz}
               netID={this.props.netID}
               name={this.props.name}>
      </PCRForm>
    )
  }

  renderRedirect() {
    return (
      <>
        <h2 className="col-xs-12">RT-PCR Test Registration</h2>
        <Redirect 
          to={{
            pathname: "/locations-hours"
          }}
        />
      </>
    );
  }

  renderUnauthorized() {
    return (
    <>
    <h2 className="col-xs-12">RT-PCR Test Registration</h2>
    <div className="col-xs-12">
      <div className="callout callout-red">
        <h4>Not Authorized</h4>
        <p>You are not authorized to register for a COVID-19 RT-PCR test at this time.</p>
        <Link to='/'>
            Return to Registration Home
        </Link>
      </div>
    </div>
    </>
    )
  }

  renderLogin() {
    return (
    <>
    <h2 className="col-xs-12">RT-PCR Test Registration</h2>
    <div className="col-xs-12">
      <div className="callout callout-red">
        <p>Please log in with your NetID to register for COVID-19 testing.</p>
        <div className="mt-4">
          <CustomOAuthButton variant="primary" size="lg">LOGIN</CustomOAuthButton>
        </div>
      </div>
    </div>
    </>
    )
  }

  renderLoading() {
    return (
      <div className="mx-auto row" style={{width:75}}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8"/>
      </div>
    );
  }

  render() {
    return (
      <div>
        { this.props.loading ? this.renderLoading() : 
          <>
            { this.props.authz ? 
              <>
                { (!this.props.authz.authorized_pcr || this.props.authz.consent_pcr) ? 
                  this.renderUnauthorized()
                : 
                  <>
                  {
                    (this.state.userContinue) ?
                    this.renderForm()
                    :
                    this.renderVideo()
                  }
                  </>
                }
              </>
            : 
              this.renderLogin()
            }
          </>
        }
      </div>
    );
  }
}