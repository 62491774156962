import React, { Component } from "react";
import CustomOAuthButton from '../CustomOAuthButton';
import TestList from './TestList';
import Spinner from 'react-spinkit';

import "../css/Home.css";

export default class Home extends Component {
 
  renderLander() {
    return (
      <div className="lander">
        <h1>COVID-19 Test Registration</h1>
        <p>Log-in with your NetID to register for COVID-19 testing.</p>
        <div className="mt-6">
          <CustomOAuthButton variant="primary" size="lg">LOGIN</CustomOAuthButton>
        </div>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>COVID-19 Test Registration</h1>
        <div className="callout callout-red">
          <h4>Not Authorized</h4>
          <p>You are not authorized to register for COVID-19 testing at this time.</p>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="mx-auto row" style={{width:75}}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8"/>
      </div>
    );
  }

  renderTestList() {
    return (
      <TestList
        authz={this.props.authz}
        refreshAuthz={this.props.refreshAuthz}
      />
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && this.props.loading) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && !this.props.loading && this.props.authz) && this.renderTestList()}
        {(this.props.authn === 'signedIn' && !this.props.loading && !this.props.authz) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
