import React, { Component } from "react";
 
export default class TestingRequirements extends Component {

  render() {
    return (
      <>
        <h2 className="col-xs-12">COVID-19 Testing</h2>
        <div className="col-xs-12">
          <p>
            Weekly COVID-19 testing is no longer mandatory for any students or employees. However, we will continue to offer voluntary, free, and convenient COVID-19 testing on main campus. Please visit the <a href="https://covid19.arizona.edu/covid19-testing/locations" target="_blank" rel="noopener noreferrer">COVID-19 testing page</a> for more information.</p>
          <p>
            In the event of a significant COVID-19 outbreak in a shared student housing setting, the University may require students and/or employees to comply with mandatory COVID-19 testing. Prior to implementing this requirement, the University will receive approval from the Arizona Department of Health Services.
          </p>
          <p>
            For all other questions or difficulties regarding testing, please contact the Testing Call Center at 833-345-0246 (toll-free), 520-848-4030, or <a href="mailto:UACOVID-19Testing@arizona.edu">UACOVID-19Testing@arizona.edu</a>.
          </p>
        </div>
      </>
    );
  }
}
