import React, { Component } from "react";

export default class GuardianConsent extends Component {

  render() {
    return (
      <div className="mt-4 col-xs-12">
        <h3>Guardian Consent Required</h3>
        <p>
          Our records indicate that you are under the age of 18. You must have your parent or legal guardian sign a consent form before you can register. If your guardian is present, they can fill out the consent form <a target="_blank" rel="noopener noreferrer" href={`${this.props.link}&netid=${this.props.netID}&student_name=${this.props.name}&cc_code=${this.props.consentCode}&test_type=${this.props.testType}`}>here.</a>
        </p>
        <p>
          Otherwise, please email them the following link:<br></br>
          <a target="_blank" rel="noopener noreferrer" href={`${this.props.link}&netid=${this.props.netID}&student_name=${this.props.name}&cc_code=${this.props.consentCode}&test_type=${this.props.testType}`}>
          {this.props.link}&netid={this.props.netID}&student_name={this.props.name}&cc_code={this.props.consentCode}&test_type={this.props.testType}
          </a>
        </p>
        <p>
          Once your parent or guardian has signed the form, you can return here to register for your test.
        </p>
      </div>
    );
  }
}

