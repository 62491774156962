import React, { Component } from "react";

export default class AntigenConsentAuth extends Component {

  render() {
    return (
      <div className="mt-4 col-xs-12">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              Informed Consent for Coronavirus (COVID-19) Testing
            </h3>
          </div>
          <div className="panel-body">
            <p><strong>Please carefully read and sign the following informed consent:</strong></p>

            <h3>Consent to Test</h3>

            <p>
              By signing below, I, (or my legally authorized representative on my behalf) authorize the University of Arizona and its staff to conduct sample collection for COVID-19 antigen testing with an anterior nares swab (just inside the nostril). This is a rapid result test for active SARS-COV-2 virus and my results will be returned to me within 2 hours.
            </p>

            <p>
              I consent to be tested with the following test types:
              <ul>
                <li>
                  Quidel Sofia2 Antigen Test. More information about this test can be found at: <a target="_blank" rel="noopener noreferrer" href="https://www.fda.gov/media/137887/download">https://www.fda.gov/media/137887/download</a>
                </li>
                <li>
                  Quidel QuickVue Antigen Test. More information about this test can be found at: <a target="_blank" rel="noopener noreferrer" href="https://www.fda.gov/media/144667/download">https://www.fda.gov/media/144667/download</a>
                </li>
              </ul>
            </p>

            <p>
              I further authorize the University and its staff to contact me, in the event my result is positive, and provide treatment as necessary to effectively assess and maintain my health, and to assess, diagnose and treat my illness or injury.
            </p>

            <p>
              I understand that the Test All Test Smart team is only collecting my testing sample and cannot provide me with medical advice and that if I have any questions or concerns about my result, I should reach out to Campus Health Services or my own medical provider.
            </p> 

            <p>
              I understand that my personal information, test results, and other corresponding information pertaining to my test will be disclosed to the county, state, or to any other governmental agency as may be required by law and as further described in the Authorization for the Release of Coronavirus (COVID-19) Test Results.
            </p>

            <p>
              I understand that, as with any medical test, there is the potential for a false positive or a false negative result to occur. I agree to notify Campus Health Services in the event that I start showing signs and symptoms of COVID-19, regardless of my test result.
            </p>

            <p>
              I understand that if I test positive for COVID-19, I will be advised to isolate for a period of time according to <a href="https://health.arizona.edu/covidpositive" target="_blank" rel="noopener noreferrer">Campus Health guidance</a>. If I live in a dorm, I understand that I will be expected to move to an isolation dorm or other location for the isolation period.
            </p>

            <p>
              I, the undersigned, have been provided information about the test purpose, procedures, possible benefits and risks, and I have received a copy of this informed consent. If I have any questions, I may call the COVID Ambassador Team (CAT) Hotline at 520-848-4045 or <a href="mailto:covidhelp@arizona.edu">covidhelp@arizona.edu</a>. I have been given the opportunity to ask questions before I sign, and I have been told that I can ask other questions at any time.
            </p>

            <hr></hr>
            <label className="text-red">Check the box to sign *</label>
            <div className="checkbox">
              <label htmlFor="consentToTest">
                <input
                  name="consentToTest"
                  id="consentToTest"
                  type="checkbox"
                  checked={this.props.consentToTest}
                  onChange={this.props.handleCheck}/>I consent to testing using either or both of the tests identified above.
              </label>
            </div>

            <h3>Text Messaging Consent</h3>

            <div className="input-group">
              <div className="callout callout-info">
                We can send you automated text messages with updates on the status of your COVID-19 test registration and results. Message and data rates may apply. Message frequency varies. You may reply to text messages received with &quot;HELP&quot; for assistance and &quot;STOP&quot; to stop receiving messages at any time.
                <br/><br/>
                Communications sent via text messaging may not be secure. By opting-in to receiving text messages, you are acknowledging that you realize that text communications are not a completely secure means of communication. While the University has taken reasonable measures to protect the confidentiality of your information, there is some risk that any information sent via text message may be disclosed or intercepted by unauthorized third parties.
                <br/><br/>
                Use of text messaging is not required. Use of email messaging or the Test All Test Smart results portal are alternatives that are available to you.
                <span className="form-required" style={{ color: 'red' }} title="This field is required.">*</span>
              </div>
              <div className="radio">
                <label>
                  <input type="radio" name="smsOptInRegResults" value="Y" onChange={this.props.handleChange} />
                  <strong>Yes</strong>, I wish to receive automated text messages with updates on the status of my COVID-19 test registration and results.
                </label>
              </div>
              <div className="radio">
                <label>
                  <input type="radio" name="smsOptInRegResults" value="N" onChange={this.props.handleChange} />
                  <strong>No</strong>, I do not wish to receive automated text messages with updates on the status of my COVID-19 test registration and results.
                </label>
              </div>
            </div>
            <div className="input-group">
              <div className="callout callout-info">
                We can send you automated text messages about COVID-19 mitigation strategies on campus, including reminders to test weekly and vaccination news. Message and data rates may apply. Message frequency varies. You may reply to text messages received with &quot;HELP&quot; for assistance and &quot;STOP&quot; to stop receiving messages.
                <span className="form-required" style={{ color: 'red' }} title="This field is required.">*</span>
              </div>
              <div className="radio">
                <label>
                  <input type="radio" name="smsOptInRemindersNews" value="Y" onChange={this.props.handleChange} />
                  <strong>Yes</strong>, I wish to receive automated text messages about COVID-19 mitigation strategies on campus, including reminders to test weekly and vaccination news.
                </label>
              </div>
              <div className="radio">
                <label>
                  <input type="radio" name="smsOptInRemindersNews" value="N" onChange={this.props.handleChange} />
                  <strong>No</strong>, I do not wish to receive automated text messages about COVID-19 mitigation strategies on campus, including reminders to test weekly and vaccination news.
                </label>
              </div>
            </div>

            <h3>COVID-19 Testing and Vaccination Research Opt-In</h3>

            <div className="input-group">
              <div className="callout callout-info">
                <p>
                  The University of Arizona would like to offer you the opportunity to <strong>opt in</strong> to have your de-identified testing data and de-identified vaccination data available for future COVID-19 research.
                </p>
                <p>
                  <a href="https://privacy.arizona.edu/privacy-resources" target="_blank" rel="noopener noreferrer">De-identified data</a> is defined as data that has <strong>all personally identifiable information removed</strong> to prevent someone&apos;s personal identity from being linked back to the data.
                </p>
                <p>
                  All research proposals that include a request to access, use, or share COVID-19 institutional data are subject to review and approval by the University of Arizona Institutional Review Board (IRB) and/or the COVID-19 Data Governance Committee.
                </p>
              </div>
              <div className="checkbox">
                <label htmlFor="researchOptIn">
                  <input
                      name="researchOptIn"
                      id="researchOptIn"
                      type="checkbox"
                      checked={this.props.researchOptIn}
                      onChange={this.props.handleCheck}/>I <strong>opt in</strong> to having my de-identified testing data and de-identified vaccination data available for future research purposes.
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              Authorization for the Release of Coronavirus (COVID-19) Test Results
            </h3>
          </div>
          <div className="panel-body">
            <p>
              <strong>Please carefully read and sign this authorization for the release of information:</strong>
            </p>
            <p>
              By signing below, I, (or my legally authorized representative on my behalf) am authorizing the University of Arizona (University) to release information pertaining to my COVID-19 antigen test conducted by the University to the individuals or units as described in this authorization.
            </p>
            <p>
              I understand that the provision of a COVID-19 antigen test with an anterior nares swab (just inside the nostril) as part of Test All Test Smart is conditioned on me signing this authorization. The purpose of the provision of the antigen test is to create information for my and the University's use to help promote my and the campus' health and safety. I further understand that not signing this authorization does not limit my ability to obtain similar services from Campus Health.
            </p>
            <p>
              I authorize my name, demographics, contact information, COVID-19 antigen test result, and other information pertaining to my COVID-19 antigen test to be released as outlined below: 
            </p>
            <ol type="a">
              <li>
                Campus Health: for purposes of coordination of care and treatment
              </li>
              <li>
                Housing and Residential Life for students who live in on-campus housing only: for purposes of isolation and quarantine procedures and protocols
              </li>
              <li>
                SAFER (Student Aid for Field Epidemiology Response) for Pima County residents only: for purposes of contact tracing
              </li>
              <li>
                Facilities Management: disclosure of building and room number only for purposes of cleaning and disinfection protocols and situational awareness in responding to building alarms
              </li>
              <li>
                University Personnel: identifiable and de-identified information will be provided to the University's COVID-19 Data Governance Group, Public Health Advisory Coronavirus Team (PHACT), Incident Command System (ICS), and the COVID-19 Response Team for the purpose of campus community health monitoring and COVID-19 response efforts, including but not limited to, providing COVID-19 exposure notifications and informing community testing and/or sampling methods.
              </li>
              <li>
                Pima County Health Department and/or Arizona Department of Health Services as required by Arizona law.
              </li>
            </ol>
            <p>
              I, or my legally authorized representative, have the right to revoke this authorization at any time by submitting a written notice of revocation to University of Arizona Health Sciences Biorepository; Arizona Health Sciences Center, Room 6122; 1501 N. Campbell Ave.; Tucson, AZ  85724. However, I understand that my records may have already been released. The information disclosed per this authorization may be subject to redisclosure and no longer protected by applicable federal or state laws.
            </p>
            <p>
              <strong>EXPIRATION DATE:</strong> This authorization is good for one year from the date of signature.
            </p>
            <hr></hr>
            <label className="text-red">Check the box to sign *</label>
            <div className="checkbox">
              <label htmlFor="authorizeRelease">
                <input
                    name="authorizeRelease"
                    id="authorizeRelease"
                    type="checkbox"
                    checked={this.props.authorizeRelease}
                    onChange={this.props.handleCheck}/>I authorize the release of my results.
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

