import React, { Component } from "react";
import {dormOptions} from './Choices'

export default class Start extends Component {

  render() {
    return (
      <>
        <div className="form-group col-xs-12">
          <label htmlFor="resType">
            Which of the following are you? <span className="text-red">*</span>
          </label>
          <div className="radio">
            <label htmlFor="resTypeDorm">
              <input 
                type="radio"
                name="resType"
                id="resTypeDorm"
                onChange={this.props.handleResType}
                value="D"
              />
              Student living on-campus
            </label>
          </div>
          <div className="radio">
            <label htmlFor="resTypeNonDorm">
              <input
                type="radio"
                name="resType"
                id="resTypeNonDorm"
                onChange={this.props.handleResType}
                value="ND"/>
                Student living off-campus
            </label>
          </div>
          <div className="radio">
            <label htmlFor="resTypeEmployee">
              <input
                type="radio"
                name="resType"
                id="resTypeEmployee"
                onChange={this.props.handleResType}
                value="FS"/>
                Employee
            </label>
          </div>
        </div>

        { this.props.resType === 'D' &&
          <div className="form-group col-xs-12">
            <label htmlFor="dorm">Which residence hall do you live in? <span className="text-red">*</span></label>
            <select
              value={this.props.dorm}
              onChange={this.props.setDorm}
              name='dorm'
              id="dorm"
              className='form-control'>
              {dormOptions.map((option) => <option key={option.display} value={option.value}>{option.display}</option>)}
            </select>
          </div>
        } 
      </>
    );
  }
}